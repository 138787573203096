import { css } from 'astroturf';
import { graphql } from 'gatsby';
import React from 'react';
import CTA from '../../components/CTA';
import DocHeader from '../../components/DocHeader';
import DocsListing from '../../components/DocsListing';
import FAQListing from '../../components/FAQListing';
import SEO from '../../components/SEO';
import DocSupport from '../../components/docs/support';
import Layout from '../../layout';

const styles = css`
  .docContainer {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
    @media (min-width: 40rem) {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  .menu {
    & > ul {
      display: none;
    }

    & button {
      font-weight: 400;
      display: inline-block;
      height: 30px;
      line-height: 28px;
      border-radius: 30px;
      font-size: 1rem;
      padding: 0 1rem;
      background: #107db5;
      color: #fff;
      border: 1px solid #107db5;
      transition: 0.25s ease-out all;
      margin: 0 0 0.5rem;
      &:hocus {
        background: transparent;
        color: #107db5;
      }
    }
    @media (min-width: 40rem) {
      overflow-y: visible;
      max-height: initial;
      flex: 1;
      display: block;

      & > button {
        display: none;
      }
      & > ul {
        display: block;
      }
    }
  }

  .open {
    & > ul {
      display: block;
    }
  }

  .content {
    flex: 2;

    & > *:first-child {
      margin-top: 0;
      margin-bottom: 0;
    }
    @media (min-width: 40rem) {
      padding-left: 1.5rem;
    }
  }
  .dirNav {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;

    & svg {
      vertical-align: text-bottom;
      transform: translateY(-2px);
    }
  }
`;

class Index extends React.Component {
  constructor() {
    super();

    this.state = {
      menuShown: false,
    };
  }

  showMenu = () => {
    const { menuShown } = this.state;
    this.setState({ menuShown: !menuShown });
  };

  render() {
    const menuClasses = [styles.menu, this.state.menuShown ? styles.open : undefined].filter((x) => x).join(' ');

    const docEdges = this.props.data.docs.edges;
    const faqEdges = this.props.data.faqs.edges;
    return (
      <Layout>
        <SEO
          title="Frequently asked questions"
          keywords={['']}
          pathname={this.props.location.pathname}
        />
        <DocHeader />
        <div className="widePage">
          <div className={styles.docContainer}>
            <div className={menuClasses}>
              <button type="button" onClick={this.showMenu}>
                {this.state.menuShown ? 'Hide menu' : 'Show menu'}
              </button>
              <DocsListing docEdges={docEdges} />
            </div>
            <section className={styles.content}>
              <h1 id="frequently-asked-questions">Frequently asked questions</h1>
              <FAQListing docEdges={faqEdges} />
              <DocSupport />
            </section>
          </div>
        </div>
        <CTA />
      </Layout>
    );
  }
}

export default Index;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query FaqDocIndexQuery {
    faqs: allMdx(
      filter: { fields: { collection: { eq: "faq" } } }
      limit: 2000
      sort: { fields: [fields___slug], order: DESC }
    ) {
      edges {
        node {
          body
          fields {
            slug
          }
          frontmatter {
            title
            category
          }
        }
      }
    }
    docs: allMdx(
      filter: { fields: { collection: { eq: "docs" } } }
      limit: 2000
      sort: { fields: [fields___slug], order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
