import { css } from 'astroturf';
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';
import React from 'react';

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';

const { renderToStaticMarkup } = require('react-dom/server');

function escapeDoubleQuotes(str) {
  return str.replace(/\\([\s\S])|(")/g, '\\$1$2'); // thanks @slevithan!
}

function renderHTML(rawMDX) {
  return escapeDoubleQuotes(renderToStaticMarkup(React.createElement(MDXRenderer, null, rawMDX)));
}

const styles = css`
  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .item + .item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .header {
    padding: 1rem;
    cursor: pointer;
    color: #107db5;
    font-size: 1.125rem;
    transition: 0.25s ease-in-out color;

    &:hocus {
      color: #000;
    }

    &::before {
      display: inline-block;
      content: '';
      height: 10px;
      width: 10px;
      margin-right: 12px;
      border-bottom: 2px solid currentColor;
      border-right: 2px solid currentColor;
      transform: rotate(-45deg);
      transition: 0.25s ease-in-out transform;
    }

    &[aria-expanded='true']::before,
    &[aria-selected='true']::before {
      transform: rotate(45deg) translateY(-0.25rem);
    }
  }

  .content {
    padding: 1rem 1rem 2rem;
    animation: fadein 0.35s ease-in;
  }
`;

class FAQListing extends React.Component {
  getDocList() {
    const docList = [];
    this.props.docEdges.forEach(docEdge => {
      docList.push({
        title: docEdge.node.frontmatter.title,
        category: docEdge.node.frontmatter.category,
        body: docEdge.node.body,
        slug: docEdge.node.fields.slug.split('/')[1],
      });
    });
    return docList;
  }

  render() {
    const docList = this.getDocList();

    const jsonLD = `{
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [${docList
        .map(
          post => `{
          "@type": "Question",
          "name": "${post.title}",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "${renderHTML(post.body)}"
          }
        }
          `
        )
        .join(',')}]
    }`;

    return (
      <>
        <h3>Usage</h3>
        <Accordion allowMultipleExpanded allowZeroExpanded>
          {docList
            .filter(post => post.category === 'usage')
            .map(post => (
              <AccordionItem className={styles.item} key={post.title}>
                <AccordionItemHeading>
                  <AccordionItemButton className={styles.header}>{post.title}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={styles.content}>
                  <div className="childPadding">
                    <MDXRenderer>{post.body}</MDXRenderer>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            ))}
        </Accordion>

        <h3>Subscription</h3>
        <Accordion className={styles.accordion} allowMultipleExpanded allowZeroExpanded>
          {docList
            .filter(post => post.category === 'subscription')
            .map(post => (
              <AccordionItem className={styles.item} key={post.title}>
                <AccordionItemHeading>
                  <AccordionItemButton className={styles.header}>{post.title}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={styles.content}>
                  <div className="childPadding">
                    <MDXRenderer>{post.body}</MDXRenderer>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            ))}
        </Accordion>

        <h3>Other</h3>
        <Accordion allowMultipleExpanded allowZeroExpanded>
          {docList
            .filter(post => post.category === 'other')
            .map(post => (
              <AccordionItem className={styles.item} key={post.title}>
                <AccordionItemHeading>
                  <AccordionItemButton className={styles.header}>{post.title}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={styles.content}>
                  <div className="childPadding">
                    <MDXRenderer>{post.body}</MDXRenderer>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            ))}
        </Accordion>
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: jsonLD }} />
      </>
    );
  }
}

export default FAQListing;
